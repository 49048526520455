import React from 'react';

require('./detailservices.css');

class RnD extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    function addMargin() {
      window.scrollTo(0, window.pageYOffset - 80);
    }
    window.addEventListener('hashchange', addMargin);
  }

  shouldComponentUpdate() {
    // remove when no jquery is used
    return false;
  }

  render() {
    return (
      <>
        <div>
          <section className="service-individual-page rnd">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 landing-text-container rndContainer content-slide-top pr-0">
                  <h1 className="latoBlack text-white heading text-center">
                    Artificial Intelligence &amp; Machine Learning
                  </h1>
                  <div size={5} className="yellow-line mx-auto" />
                  <div className="service-text">
                    <span className="museo300 heavy-lifting">Heavy-lifting for </span>
                    <span className="museoRegular startup-unicorn">
                      Startups, Unicorns &amp; Fortune 500s
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="service-text-banner">
            <div id="services" className="service-capabilities">
              {/* Capabilities */}
              <div className="capabilities">
                <div className="container ">
                  <img
                    className="mb-3"
                    width={74}
                    height={74}
                    src={
                      require('../../assets/images/services/rnd/research-development-hover.svg')
                        .default
                    }
                  />
                  <h1 className="heading latoBlack mb-0">
                    AI
                    <span className="latoBlack"> &amp;</span>
                    ML Capabilities
                  </h1>
                  <div className="row services">
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#computer-vision">Computer Vision</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#nlp">Natural Language Processing</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#data-visual">Data Visualization</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#predictive">Predictive Analytics</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Capabilities */}
              {/* R&D development services */}
              <div className="container">
                {/* Computer vision */}
                <div className="individual step-1" id="computer-vision">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #ffb100'}}
                      >
                        Computer Vision
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        We provide services to solve complex computer vision problems like image
                        classification, object detection and tracking, various types of analysis on
                        the content of visual data and so on.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0 ">
                          <p className="text-left expertise">Object Detection</p>
                          <p className="text-left expertise">Image Segmentation</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Pattern Recognition</p>
                          <p className="text-left expertise">Facial Features Analysis</p>
                          <p className="text-left expertise">Image Similarity</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title text-left">
                              <a
                                className="accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapseTwo"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapseTwo" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-12">
                                  <p className="text-left expertise">Object Detection</p>
                                  <p className="text-left expertise">Image Segmentation</p>
                                  <p className="text-left expertise">Pattern Recognition</p>
                                  <p className="text-left expertise">Facial Features Analysis</p>
                                  <p className="text-left expertise">Image Similarity</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Computer vision */}
                {/* NLP */}
                <div className="individual step-2" id="nlp">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid  #0ec573'}}
                      >
                        Natural Language Processing
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        We provide expertise for solving various NLP problems like text
                        classification, information retrieval, annotations and topic identification
                        and dialogue generations.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 pl-lg-0 col-12">
                          <p className="text-left expertise">Entity and Intent Recognition</p>
                          <p className="text-left expertise">Text Classification</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Dialog Generation and Chatbots</p>
                          <p className="text-left expertise">JavaScript syntactical superset</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#frontend"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="frontend" className="panel-collapse collapse">
                            <div className="panel-body step-2">
                              <div className="row">
                                <div className="col-12">
                                  <p className="text-left expertise">
                                    Entity and Intent Recognition
                                  </p>
                                  <p className="text-left expertise">Text Classification</p>
                                  <p className="text-left expertise">
                                    Dialog Generation and Chatbots
                                  </p>
                                  <p className="text-left expertise">
                                    JavaScript syntactical superset
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* NLP */}
                {/* Data visualization */}
                <div className="individual step-1" id="data-visual">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #914df6'}}
                      >
                        Data Visualization
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <div className="vertical-line">
                        <p className="description">
                          Our team can help you create useful visualisation solutions for your data
                          to help you develop a better understanding of your data.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0">
                          <p className="text-left expertise">Probabilistic Distributions</p>
                          <p className="text-left expertise">Graphical Models</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Feature Importance Metrics</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse-dev"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse-dev" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-lg-6 col-12 pl-lg-0">
                                  <p className="text-left expertise">Probabilistic Distributions</p>
                                  <p className="text-left expertise">Graphical Models</p>
                                  <p className="text-left expertise">Feature Importance Metrics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Data visualization */}
                {/* Predictive Analytics */}
                <div className="individual step-2" id="predictive">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid  #0093ff'}}
                      >
                        Predictive Analytics
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        Our AI engineers can help you retrieve useful insights from your data and
                        perform predictive analytics for maximising your output from data.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12  pl-lg-0">
                          <p className="text-left expertise">Probabilistic Analysis</p>
                          <p className="text-left expertise">Graphical Models</p>
                        </div>
                        <div className="col-lg-6 col-12  pl-lg-4">
                          <p className="text-left expertise">Data Clustering</p>
                          <p className="text-left expertise">Deep Predictive Modeling</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className="accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse-sys"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse-sys" className="panel-collapse collapse">
                            <div className="panel-body step-2">
                              <div className="row">
                                <div className="col-lg-6 col-12  pl-lg-0">
                                  <p className="text-left expertise">Probabilistic Analysis</p>
                                  <p className="text-left expertise">Graphical Models</p>
                                  <p className="text-left expertise">Data Clustering</p>
                                  <p className="text-left expertise">Deep Predictive Modeling</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Predictive Analytics */}
              </div>
            </div>
            {/* R&D development services */}
          </section>
        </div>
      </>
    );
  }
}

export default RnD;
